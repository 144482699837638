import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/fonts/fonts.css";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: window.localStorage.getItem("lang")
    ? window.localStorage.getItem("lang")
    : "zh",
  messages: {
    zh: require("@/assets/lang/zh.js"),
    en: require("@/assets/lang/en.js"),
  },
  silentTranslationWarn: true,
});

Vue.filter("prefixed", (d) => {
  const API_HOST = "https://foton.zyyup.cn/";
  if (!d || typeof d !== "string") return "";
  return d.startsWith("http") ? d : API_HOST + d;
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
